<template>
  <div>

    <!-- Banner -->
    <banner
      :back-image="bannerImage"
      height="550"
    />

    <div class="container my-5">
      <h1 class="text-center">
        {{ $t('quality.title') }}
      </h1>

      <p>{{ $t('quality.text1') }} </p>

      <br>

      <p>{{ $t('quality.text2') }} </p>

      <p>{{ $t('quality.text3') }} </p>

      <ul>

        <li> {{ $t('quality.li1') }} </li>

        <li> {{ $t('quality.li2') }} </li>

        <ol>

          <li> {{ $t('quality.li21') }} </li>

          <li> {{ $t('quality.li22') }} </li>

          <li> {{ $t('quality.li23') }} </li>

        </ol>

        <li> {{ $t('quality.li3') }} </li>

        <li> {{ $t('quality.li4') }} </li>

      </ul>

      <b-row>
        <b-col
          class="mb-4"
          lg="6"
          md="12"
        >
          <!-- Certificate 1 -->
          <img
            width="100%"
            :src="certificate"
            alt="Certificate"
          >
        </b-col>

        <b-col
          class="mb-4"
          lg="6"
          md="12"
        >
          <!-- Certificate 2 -->
          <img
            width="100%"
            :src="certificate2"
            alt="Certificate"
          >
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import MyData from 'Data/commitments.json'

import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'QualityEnvironment',
  components: {
    Banner,
    BRow,
    BCol,
  },
  data() {
    return {
      bannerImage: MyData.data.qualityImage,
      certificate: MyData.data.qualityCertificate,
      certificate2: MyData.data.qualityCertificate2,
    }
  },
}
</script>
<style scoped>

  p {
    margin-right: 20px;
    margin-left: 20px;
  }

</style>
